<template>
	<div class="ct_nav_wrap">
		<!-- 메뉴영역 -->
		<div class="ct_nav">
			<!-- 프로필 -->
			<div class="nav_profile">
				<!-- nft카드 닉네임 -->
				<div class="profile_login clear">
					<div class="profile_thumb">
						<img
							v-if="item_cartel.profle_nft_card_img_url"
							:src="$request.upload_url(item_cartel.profle_nft_card_img_url)" alt=""
						>
						<img
							v-else
							:src="require('@/assets/image/@noimage.png')" alt="noimage"
						>
					</div>
					<div class="profile_info">
						<strong class="profile_nickname">{{ item_cartel.nickname }}</strong>
						<p class="login_status"> {{ item_cartel.cartl_member_grade_name }}</p>
					</div>
				</div>
				<!-- 설정버튼 -->
				<div
					v-if="is_setting"
					class="profile_set"
				>
					<button
						@click="toSetting"
						class="btn_setup"
					>
						<span class="hide">설정</span>
					</button>
				</div>
				<!-- 설정버튼 -->
				<hr class="hr_solid"><!-- 구분선 -->
			</div>
			<!-- 프로필 -->
			<div class="scroll_h">
				<!-- 전체글보기 -->
				<div class="menu_first">
					<a
						@click="toAll"
						class="all"
					>전체글 보기</a>
				</div>
				<!-- 전체글보기 -->
				<hr class="hr_dashed"><!-- 구분선 -->
				<!-- 기본메뉴 -->
				<div class="menu_area">
					<h3 class="hide">각 게시판 보기</h3>
					<ul class="menu_text_area">
						<li
							v-for="(item, index) in fav_list"
							:key="'fav_' + index"
						>
							<button
								@click="to(item)"
								class="tit_text"
							>{{ item.board_name }}</button>
							<label
								v-if="(item.board_type_code == 'CA00700001' || item.board_type_code == 'CA00700002')"
								aria-label="활성화 상태" class="btn_favorites on"
							><input type="checkbox" style="visibility: hidden" /></label>
							<button
								v-else
								aria-label="활성화 상태" class="btn_favorites" :class="{ on: item.fav }"
								@click="postFav(item, index)"
							></button>
						</li>
					</ul>
				</div>
				<!-- //전체글보기 -->
			</div>
		</div>
		<!-- //메뉴영역 -->
		<!-- 백그라운드 -->
		<div
			@click="$emit('cancel')"
			class="bg_overlay" aria-label="백그라운드"
		></div>
		<!-- //백그라운드 -->
	</div>
</template>

<script>
	export default{
		name: 'mafia055'
		, props: ['user', 'cartel']
		, data: function(){
			return {
				fav: []
				, base_list: [
					{ code: 'notice', name: '공지사항', fav: 0, sort: 1}
					, { code: 'base1', name: '한줄 응원', fav: 0, sort: 2}
				]
				, item_cartel: this.cartel
				, items_board_config: []
				, main_nft: {}
			}
		}
		, computed: {
			fav_list: function(){

				return this.items_board_config.filter(function(item) {
					if(item.board_bookmark_fg == 'Y' ){
						item.fav = true
					}else{
						item.fav = false
					}
					return item
				})
			}
			, is_setting: function(){
				let t = false

				if(this.cartel.cartl_entry_state_code == 'CA00300004'){
					t = true
				}

				return t
			}
		}
		, methods: {
			postFav: async function(bbs){
				try{
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_fav
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: bbs.board_number
							, board_bookmark_fg: !bbs.fav ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getCartelMenu()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getBoardList: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_board_config_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_board_config = result.data.board_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, to: function(item){
				if(item.board_authority.inquiry_fg != 'Y'){
					this.$bus.$emit('notify', { type: 'error', message: '조회권한이 없습니다.'})
				}else{
					if(item.board_type_code === 'CA00700001'){
						this.$bus.$emit('to', { name: 'mafia127', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name }, not_query: true})
					}else{
						this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, from: this.$route.name }, not_query: true})
					}
				}
			}
			, getCartelMenu: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						,url: this.$api_url.api_path.get_cartel_menu
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.items_board_config = result.data.board_list

						this.$bus.$emit('setBoardConfigSort', this.items_board_config)
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toSetting: function(){
				this.$bus.$emit('to', { name: 'mafia074', params: { idx: this.$route.params.idx, from: 'mafia049' }})
			}
			, toAll: function(){
				this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx }})
			}
		}
		,async created() {
			await this.getCartelMenu()
			//await this.getBoardList()
		}
	}
</script>