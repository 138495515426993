<template>
	<div
		ref="post_list_item_view"
		class="mt-30 cartel"
	>
		<div
			class="flex-row"
		>
			<div
				class="box-list"
			>
				<ul
					v-if="items_cartel_bbs.length > 0"
				>
					<li
						v-for="(item, index) in list_bbs"
						:key="'item_' + index"
						class="bg-white radius-20 mb-30"
					>
						<div class="flex-row justify-space-between pa-20 under-line">
							<div class="flex-row">
								<div
									v-if="item.board_type_code == 'CA00700001'"
								>{{ item.post_title }}</div>
								<template
									v-else
								>
									<span  class="inline-block icon icon-30 color-black mr-10">
										<img
											v-if="item.profle_nft_card_img_url"
											:src="$request.upload_url(item.profle_nft_card_img_url)"
										/>
										<img
											v-else
											:src="require('@/assets/image/web/mypage_cartel/ico_my01_off.svg')"
											class="none"
										/>
									</span>
									<div class="flex-column justify-center " style="">{{  item.post_member_nickname }}</div>
								</template>
							</div>
							<div class="color-gray">
								{{  item.time_t }}
								<button
									@click="onMore(item, index)"
									class="post_set"
								><span class="hide">설정</span></button>
							</div>
						</div>
						<div class="under-line">
							<div class="text-center">
								<carousel
									:perPage="1"
								>
									<slide
										v-for="(file, f_index) in item.post_file_list"
										:key="'item_' + index + '_file_' + f_index"
										class="photo_collage_view bg-black "
										style=" min-height: 120px; max-height: 350px; overflow: hidden"
									>
										<div
											v-if="file.appendix_file_div_code.indexOf('CA01000001') > -1"
											class="flex-column justify-center full-height"
										>
											<div
												style="object-fit: cover;"
											>
												<img
													:src="$request.upload_url(file.file_url)"
												/>
											</div>
										</div>
										<video
											v-if="file.appendix_file_div_code.indexOf('CA01000002') > -1"

											controls
											style="object-fit: cover; max-height: 350px"
										>
											<source
												:src="file.file_url"
											>
										</video>
										<div
											v-if="file.appendix_file_div_code.indexOf('CA01000003') > -1"
											class="flex-column " style="position: static !important;"
										>
											<figure class="audio_wrap" style="height: 300px;">
												<div class="audio_bg">
													<span class="hide">사운드배경이미지</span>
												</div>
											</figure>
											<div style="width: 480px; overflow: hidden; margin: 0 auto">
												<video
													controls
													style="width: 100%; height: 50px; padding: 10px"
												>
													<source
														:src="file.file_url"
													>
												</video>
											</div>
										</div>
									</slide>
								</carousel>
							</div>

							<div
								class=" pa-20"
								@click="toDetail(item, index)"
							>
								<button
									v-if="item.vote_fg == 'Y'"
									class="btn-inline btn-primary radius-20 pa-5-10"
								>투표</button>
								{{  item.contents }}
							</div>

							<div
								class=" prl-20 color-gray cursor-pointer"
								@click="toDetail(item, index)"
							>
								더보기
							</div>

							<div class=" pa-20 color-gray">
								<span class="mr-10">공감 <span>{{  item.sympaty_count }}</span>명</span>
								<span>댓글 <span>{{  item.comment_count }}</span>개</span>
							</div>
						</div>
						<div class="flex-row justify-space-between  pa-20">
							<div>
								<button
									@click="$set(item, 'is_emoji', !item.is_emoji)"
								><span class="add_status add_empathy " :class="{ on: item.is_empathy}">공감</span></button>
							</div>
							<div>
								<a
									@click="toComment(item, index)"
								><span class="add_status add_comment">댓글</span></a>
							</div>
						</div>
					</li>
				</ul>
				<div
					v-else
					class="pa-20 bg-white radius-20 flex-column justify-center"
					style="height: 480px"
				>
					<div><img :src="require('@/assets/image/list_none.png')" alt="이력없음"></div>
					<div class="mt-30 color-gray">등록된 게시물이 없습니다.</div>
				</div>

				<Pagination
					:options="item_search"
					@click="getCartelBbs"
				></Pagination>
			</div>

			<CartelEtc
				:user="user"
				:cartel_info="item_cartel"
				:bbs_info="{}"
			></CartelEtc>
		</div>

		<div
			v-if="is_join"
			class="cartel_join"
		>
			<button @click="$emit('to', { name: 'mafia050', params: { idx: idx_cartel}} )">
				<div class="cartel_join_btn">
					<span>카르텔<br><em>가입</em></span>

				</div>
			</button>
		</div>

		<div
			v-if="is_write"
			class="cartel_write"
		>
			<button
				@click="$emit('to', {name: 'mafia1271', params: {idx: $route.params.idx, code: $route.params.code, b_id: $route.params.b_id}})"
			>
				<div class="cartel_write_btn">
					<span class="hide">글쓰기</span>
				</div>
			</button>
		</div>

		<mafia055
			v-if="is_side"
			:user="user"
			:cartel="item_cartel"
			@to="to"

			@cancel="is_side = false"
		></mafia055>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_profile"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<mafia064
			v-if="is_more"

			:user="user"
			:item_bbs="item_more"
			@cancel="is_more = false"
			@deleteBbs="deleteBbs"
			@move="moveBbs"
			@modify="onModify"
			style="z-index: 1003"
		>
		</mafia064>

		<PopupConfirm
			v-if="is_delete"

			@click="deleteBbs"
			@cancel="is_delete = false"
		>
			<template
				v-slot:title
			>게시글 삭제</template>
			<template
				v-slot:main-txt
			>해당 게시글을 삭제 하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>게시글을 삭제 하시려면 확인을 눌러주세요.</template>
		</PopupConfirm>

		<cartel_bbs_detail
			v-if="is_item_detail"

			:user="user"
			:cartel="item_cartel"
			:board="item_board_config"
			:bbs="item_more"
			:is_item_detail_comment="is_item_detail_comment"

			@cancel="update"
			@deleteBbs="deleteBbs"
			@toModify="onModify"
			class="layer-popup"
		></cartel_bbs_detail>

		<mafia1272
			v-if="is_item_modify"

			:user="user"
			:cartel="item_cartel"
			:bbs="item_more"

			@cancel="is_item_modify = false"
			@click="modify"
			class="layer-popup"
		></mafia1272>
	</div>
</template>


<script>

import { Carousel, Slide } from 'vue-carousel';
import mafia055 from '@/view/Cartel/mafia055'
import mafia064 from "@/view/Cartel/mafia064";
import PopupConfirm from "@/view/Layout/PopupConfirm";
import mafia098 from '@/view/Cartel/mafia098'
import cartel_bbs_detail from "@/view/Cartel/cartel_bbs_detail";
import Mafia1272 from "@/view/CartelBoard/mafia127-2";
import CartelEtc from "@/view/Cartel/CartelEtc";
import Pagination from "@/components/Pagination";

export default {
	name: 'mafia049'
	, props: ['user']
	, components: {
		Mafia1272,
		mafia064, mafia055, Carousel, Slide, PopupConfirm, mafia098, Pagination, cartel_bbs_detail, CartelEtc}
	, data: function(){
		return {
			program: {
				name: 'cartel'
				, title: '카르텔'
				, type: 'cartel'
				, not_header: true
				, not_footer: true
				, bg_contents: 'bg-gray01'
			}
			, item_cartel: {}
			, items_cartel: []
			, items_cartel_notice: []
			, items_cartel_bbs: []
			, idx_cartel: this.$route.params.idx
			, is_side: false
			, item_my_cartel: {}
			, is_more: false
			, item_more: {}
			, is_trans: true
			, item_board_config: {}
			, is_delete: false
			, is_098: false
			, icons_emoji: [

				{ code: 'CA01100001', type: 'best', txt: '최고예요'}
				, { code: 'CA01100002', type: 'funny', txt: '웃겨요'}
				, { code: 'CA01100003', type: 'like', txt: '좋아요'}
				, { code: 'CA01100004', type: 'sad', txt: '슬퍼요'}
				, { code: 'CA01100005', type: 'surprise', txt: '놀랐어요'}
				, { code: 'CA01100006', type: 'angry', txt: '화나요'}
			]
			, item_search: {
				search_value: ''
				, page: this.$language.base.page_number
				, list_cnt: this.$language.base.pagerecnum
			}
			, type: 'list'
			, is_add: true
			, max: false
			, is_item_detail: false
			, is_item_detail_comment: false
			, item_detail: {

			}
			, is_item_modify: false
			, item_profile: {}
		}
	}
	, computed: {
		is_write: function(){
			let t = false
			if(this.item_board_config.board_authority){

				if(this.item_board_config.board_authority.reg_fg == 'Y'){
					t = true
				}
			}
			return t
		}
		, list_bbs: function(){

			return this.items_cartel_bbs.filter((item) => {
				if(item.blok_member_fg == 'Y'){
					item.is_block = true
				}
				if(item.sympaty_icon_code != ''){
					item.is_empathy = true
				}
				if(item.post_contents){
					item.contents = item.post_contents
				}
				item.time_t = this.$date.getTimeStory(item.registdate)
				return item
			})
		}
		, is_join: function(){
			let t = false
			if(this.item_cartel.cartl_entry_state_code == ''){
				t = true
			}
			return t
		}
		, is_join_confirm: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code) {
				case 'CA00300004':
					t = true
					break
			}
			return t
		}
		, is_join_cancel: function (){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300003':
				case 'CA00300005':
				case 'CA00300006':
				case 'CA00300007':
					t = true
					break;
			}
			return t
		}
		, is_join_wait: function(){
			let t = false
			switch(this.item_cartel.cartl_entry_state_code){
				case 'CA00300001':
				case 'CA00300002':
					t = true
					break;
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data

					switch (this.item_cartel.cartl_concern_sphere_code){
						case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
						case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
						case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
						case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartelCheer: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelNotice: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						//, board_number: this.$route.params.b_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs = result.data.post_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,getCartelBbs: async function(page = 1){
			this.is_add = false
			try{
				//this.$bus.$emit('on', true)
				this.$set(this.item_search, 'page_number', page)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_board_bbs_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(result.data.post_list.length > 0){
						this.items_cartel_bbs = result.data.post_list
						this.max = false
					}else{
						this.max = true
					}
					this.$storage.setQuery(this.item_search)
					window.scrollTo(0, 0)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_more = false
				this.is_add = true
			}
		}

		, onMore: function(item, index){
			this.is_more = true
			this.item_more = item
			this.item_more.index = index
		}
		, onDelete: function(){
			this.is_more = false
			this.is_delete = true
		}
		, handleClick(event, ref) {
			console.log(this.$refs, event, ref)
			this.$refs[ref][event]();
		}
		, getBoardConfig: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})

				if (result.success) {
					this.item_board_config = result.data

					await this.getBbsList()
				} else {
					if(result.code == 'E001201021'){
						this.$bus.$emit('notify', {type: 'error', message: result.result_msg})
					}
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, deleteBbs: async function(){
			this.is_item_detail = false
			this.is_more = false
			this.items_cartel_bbs.splice(this.item_more.index, 1)
		}
		, moveBbs: async function(){
			this.is_more = false
			await this.mount()
		}

		, getBbsList: async function(){

			this.is_add = false

			if(this.$route.params.code == 'CA00700001'){
				await this.getCartelNotice()
			}else if(this.$route.params.code == 'CA00700002') {
				await this.getCartelCheer()
			}else if(this.$route.params.code){
				await this.getCartelBbs()
			}

			let self = this
			setTimeout(() => {
				self.is_add = true
			}, 500)
		}
		, mount: async function(){
			this.$emit('onLoad', this.program)

			this.is_side = false
			this.items_cartel_bbs = []
			this.item_search = {
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			}
			document.body.scrollTop = 0

			await this.getCartel()
			await this.getBoardConfig()

		}

		, postEmoji: async function(item, emoji){
			try{
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_emoji
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
						, sympaty_icon_code: emoji.code
					}
					, type: true
				})

				if(result.success){
					item.is_empathy = emoji.code
					item.is_emoji = false
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_emoji = false
				this.$bus.$emit('on', false)
			}
		}
		, toCartel: function(){
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.$route.params.idx }})
		}
		, toSearch: function(){
			this.$bus.$emit('to', { name: 'mafia0531', params: this.$route.params})
		}
		, toDetail: function(item){

			/*
			this.item_more = item

			this.is_item_detail = true
			this.is_item_detail_comment = false

			let path = this.$route.path.split('#')
			console.log('toDetail', this.$route.path, path)
			if(path[1] == 'detail'){
				this.$router.push(path[0])
			}else{
				this.$router.push(this.$route.path + '#detail')
			}


			 */
			this.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, toComment: function(item){

			this.item_more = item

			// this.is_item_detail = true
			// this.is_item_detail_comment = true

			this.$router.push(this.$route.path + '#comment')

			//this.$emit('to', { name: 'mafiaCommentList', params: { idx: this.$route.params.idx, code: item.board_type_code ? item.board_type_code : this.$route.params.code, b_id: item.board_number, bbs_id: item.bulletin_number}})
		}
		, to: function(params){
			this.$emit('to', params)
		}
		, onModify: function(){
			this.is_item_modify = true
		}
		, modify: function(item){
			this.is_more = false
			this.is_item_modify = false
			console.log('modify', item)
			this.getBbs(item)
			if(this.is_item_detail){
				this.is_item_detail = false
				setTimeout(() => {
					this.is_item_detail = true
				}, 5)
			}
		}

		, getBbs: function(item){
			switch (item.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo(item)
					break
				case 'CA00700002':
					this.getCartelCheerInfo(item)
					break
				default:
					this.getCartelBbsInfo(item)
					break
			}
		}

		, getCartelBbsInfo: async function(item){
			try{
				if(!item.bulletin_number){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					let bbs = result.data

					this.item_more.contents = bbs.post_contents

					if(bbs.post_file_list.length > 0){
						for(let i = 0; i < bbs.post_file_list.length; i++){
							this.item_more.post_file_list[i].appendix_file_div_code = bbs.post_file_list[i].post_appendix_file_div_code
							this.item_more.post_file_list[i].file_url = bbs.post_file_list[i].post_file_url
						}
					}

					this.item_more.comment_count = bbs.comment_tot_count
					this.item_more.sympaty_count = bbs.sympaty_list.length

					this.$set(this.items_cartel_bbs, this.item_more.index, this.item_more)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelNoticeInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data

					this.is_vote = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.item_bbs.title = this.item_bbs.post_title
					this.item_bbs.is_comment = this.item_bbs.comment_writing_fg == 'Y' ? true : false

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							console.log(key)
							this.files.push({
								name: val.post_file_name
								, src: val.post_file_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(item){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: item.cartl_number
						, board_number: item.board_number
						, bulletin_number: item.bulletin_number
					}
					, type: true
				})

				if(result.success){
					this.items_cartel_bbs[this.item_more.index] = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, update: function(item){

			this.item_more.sympaty_icon_code = item.sympaty_icon_code
			this.is_item_detail = false
			this.getBbs(this.item_more)
			let path = this.$route.path.split('#')
			this.$router.push(path[0])
		}
		, on098: function(item){
			this.is_098 = true
			this.item_profile = item
		}
	}
	,async  created() {
		await this.mount()
	}
	, watch:{
		$route(to, from){
			this.mount()
			console.log(to, from)
			this.is_item_detail = false
			this.is_item_detail_comment = false
			if(to.hash){
				switch (to.hash){
					case '#detail':
						this.is_item_detail = true
						break
					case '#comment':
						this.is_item_detail = true
						this.is_item_detail_comment = true
						break
				}
			}
		}
	}
}
</script>

<style>
.VueCarousel-dot-container { margin-top: 0px !important;}
.VueCarousel-dot-container button { margin-top: 0px !important; }
</style>