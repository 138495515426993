<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="goBack"
							class="arrow_white">
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ text_title }}</h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
			<div class="save mt-10">
				<button
					@click="doPost"
					class="btn_save"
					:disabled="is_disabled"
				>완료</button>
			</div>
		</div>
		<!-- //고정해더 -->
		<!-- 글쓰기 영역 -->
		<div class="write_wrap pt-70 pb-50">
			<div class="container">
				<div
					v-if="!is_notice"
					class="article_write_form_subject"
				>
					<span>{{ text_board_name }}</span>
				</div>
				<div
					v-show="is_notice"
					class="article_write_form"
				>
					<!-- 댓글 작성 -->
					<div class="article_write_option_check">
						<span class="option_name">댓글 작성</span>
						<em class="option_desc">댓글 작성 여부를 선택합니다.</em>
						<div class="option_check-toggle">
							<p class="toggle-group">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<button class="btn-toggle" :class="{ on: item_bbs.is_comment}" @click="item_bbs.is_comment = !item_bbs.is_comment"></button>
							</p>
						</div>
					</div>
					<!-- //댓글 작성 -->
					<!-- 제목입력 -->
					<div class="article_write_form_subject">
						<input
							v-model="item_bbs.title"
							placeholder="제목을 입력하세요."
							class="input_text"
							maxlength="50"
						/>
					</div>
					<!-- //제목입력 -->
				</div>

				<!-- 미디어 영역 -->
				<div class="article_media_list_wrap">
					<div class="media_wrap">
						<draggable
							v-model="files"
							handle=".handle"

						>
							<div
								v-for="(file, index) in files"
								:key="'files_' + index"
								class="photo_upload_item"
							>
								<div
									v-if="file.type.indexOf('image') > -1"
									class="media_content_item"
								>
									<img
										:src="file.src"
										style="width: 100%"
									/>
									<button class="item_close">
										<i
											@click="removeFile(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div
									v-else-if="file.type.indexOf('video') > -1"
									class="media_content_item m_video"
								>
									<video
										width="100%"
										:ref="'video_' + index"
									>
										<source
											:src="file.src"
											type="video/webm"
										>
									</video>
									<button class="item_close">
										<i
											@click="removeFile(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div
									v-else-if="file.type.indexOf('audio') > -1"
									class="media_content_item m_music"
								>
									<audio
										:src="file.src"
									></audio>
									<button class="item_close">
										<i
											@click="removeFile(index)"
											class="icon-close"
										></i>
									</button>
								</div>
								<div class="media_content_name">
									<p>{{  file.name }}</p>
								</div>
								<div class="media_content_drag handle">
									<div class="drag_bar">
										<img :src="require('@/assets/image/btn_order.png')" alt="">
									</div>
								</div>
							</div>
						</draggable>
					</div>
				</div>
				<!-- //미디어 영역 -->

				<div class="one_editor">
					<div class="ct_text_editor">
						<!-- 글쓰기영역 -->
						<mafia_vote
							v-if="false"
							:vote="item_bbs.vote_info"
							:cartel="item_cartel"
							:bbs="item_bbs"
							:user="user"

							@cancel="removeVote"
							@change="setVote"
						></mafia_vote>

						<div class="ct_container">
							<div class="ct_textarea">
								<!-- TODO : 내용입력시 placeholde 및 .ct_empty 클래스 제거 -->
								<textarea
									v-model="item_bbs.content"
									placeholder="내용을 입력하세요."
									ref="content"

									@input="reSize"

									style="min-height: 450px"
								></textarea>
							</div>
						</div>

						<!-- //글쓰기영역 -->
						<div
							v-if="!is_cheer"
							class="ct_button_area"
						>
							<div class="container">
								<div class="toolbar_list_area">
									<ul class="toolbar_list">

										<li>
											<button><label><span class="toolbar_icon tb_photo">사진</span><input_file accept="image/*" multiple @change="setFile" /></label></button>
										</li>
										<li>
											<button><label><span class="toolbar_icon tb_viedo" >비디오</span><input_file accept="video/*" multiple @change="setFile" ref="file_video"/></label></button>
										</li>
										<li>
											<button><label><span class="toolbar_icon tb_sound" >사운드</span><input_file accept="audio/*" multiple @change="setFile" ref="file_sound"/></label></button>
										</li>
										<li>
											<button
												@click="resetVote"
											><span class="toolbar_icon tb_vote">투표</span></button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<PopupSelect
			v-if="is_select"
			@cancel="is_select = false"
		>
			<template
				v-slot:title
			>게시판 선택</template>
			<template
				v-slot:list
			>
				<template
					v-for="(item, index) in items_board_config"
				>
					<li
						v-if="item.board_type_code != 'CA00700001'"
						:key="'item_' + index"
						style="padding: 10px 0"
						@click="setBoard(item)"
					>{{ item.board_name }}</li>
				</template>
			</template>
		</PopupSelect>


		<PopupConfirm
			v-if="is_popup"
			@cancel="is_popup = false"
			@click="setVideo"
		>
			<template
				v-slot:title
			>비디오</template>
			<template
				v-slot:main-txt
			>
				유튜브 링크를 입력하세요
			</template>
			<template
				v-slot:sub-txt
			>
				<div
					v-for="i in video_size"
					:key="'i_' + i"
				>
					<input
						v-model="video[video_size - i]"
						type="text"
						style="border: 1px solid #ddd; padding: 10px"
						class="mt-10"
						placeholder="https://"
					/>
					<v-icon
						v-if="i == video_size"
						@click="setVideoSize(true, i)"
						class="ml-10 mt-5"
					>mdi-plus-circle-outline</v-icon>
					<v-icon
						v-else
						@click="setVideoSize(false, i)"
						class="ml-10 mt-5"
					>mdi-minus-circle-outline</v-icon>
				</div>
			</template>
		</PopupConfirm>
	</div>
</template>

<style>
.m_video {	display: flex; flex-direction: column; justify-content: center; border: 1px solid #ddd; background-color: var(--bg-Gray02); border-radius: 10px; overflow: hidden}

.article_write_form_subject { font-size: 16px; display: flex; justify-content: space-between; padding: 10px}

.ct_textarea textarea { height: none; min-height: none;}

</style>

<script>

import PopupConfirm from "@/view/Layout/PopupConfirm";
import PopupSelect from "@/view/Layout/PopupSelect";
import draggable from 'vuedraggable'
import mafia_vote from '@/view/Cartel/mafiaVote'
import input_file from '@/components/InputFile'

export default {
	name: 'mafia1272'
	, components: {PopupConfirm, PopupSelect, draggable, mafia_vote, input_file}
	, props:['user', 'cartel', 'board', 'bbs']
	, data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
			}
			, item_cartel: {}
			, item_board_config: {
				id: ''
			}
			, item_bbs: {
				code: this.$route.params.code
				, user_id: this.user.id
				, nick: this.user.nick
				, video:[]
				, vote: null
				, title: ''
				, content: ''
				, is_comment: true
			}
			, files: []
			, video: []
			, video_size: 1
			, items_board_config: []
			, is_select: false
			, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
			, is_popup: false
			, file_max: 8
			, is_vote: false
			, vote_fg: false
			, post_file_list: []
			, upload_files: []
			, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
			, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
			, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
			, board_type: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
		}
	}
	, computed: {
		is_disabled: function(){
			let t = false
			if(!this.item_bbs.content || this.item_bbs.content.trim() == ''){
				t = true
			}
			if(this.item_board_config.code == 'CA00700001' && (!this.item_bbs.title || this.item_bbs.title == '')){
				t = true
			}
			return t
		}
		, is_cheer: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700002'){
				t = true
			}
			return t
		}
		, text_title: function(){
			let t = '글쓰기'

			if(this.bulletin_number){
				t = '글수정'
			}

			return t
		}
		, text_board_name: function(){
			let t = ''

			if(this.bulletin_number){
				t =  this.item_board_config.board_name
			}

			return t
		}
		, is_notice: function(){
			let t = false

			if(this.board_type == 'CA00700001'){
				t = true
			}

			return t
		}
		, vote_info: function(){
			if(this.vote_fg){
				return {
					vote_info: ''
					, vote_title: this.item_bbs.vote.title
					// , vote_startdt: this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
					, vote_enddate: this.makeEnddate()
					, vote_type_code: this.item_bbs.vote.is_multiple ? 'CA01400002' : 'CA01400001'
				}
			}else{
				return {

				}
			}

		}
	}
	,methods: {
		getBoardConfig: async function(){
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
					}
					, type: true
				})

				if(result.success){
					this.item_board_config = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postFiles: async function (){
			try{
				this.post_file_list = []
				for(let file of this.upload_files){

					console.log('file ', file)
					let post_appendix_file_div_code = ''
					let post_file_url = ''
					if(file.post_file_url){
						post_appendix_file_div_code = file.post_appendix_file_div_code
						post_file_url = file.post_file_url
					}else{

						if(file.type.indexOf('video') > -1){
							post_appendix_file_div_code = 'CA01000002'
						}else if(file.type.indexOf('audio') > -1){
							post_appendix_file_div_code = 'CA01000003'
						}else{
							post_appendix_file_div_code = 'CA01000001'
						}

						post_file_url = await this.postFile(post_appendix_file_div_code, file)
					}

					this.post_file_list.push({
						post_appendix_file_div_code: post_appendix_file_div_code
						, post_file_url: post_file_url
					})
				}
				return this.post_file_list

			}catch (e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, postFile: async function(appendix_file_div_code, file){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: 'CM00700003'
						, appendix_file_div_code: appendix_file_div_code
						, appendix_file_name: file
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					return result.data.file_url
				}else{
					throw result.message
				}

			}catch (e) {
				console.log('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postNotice: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_notice_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_title: this.item_bbs.title
							, post_contents: this.item_bbs.content
							, post_file_list: this.post_file_list
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							// , vote_fg: this.vote_fg ? 'Y' : 'N'
							// , vote_info: this.vote_info
							// , vote_list: this.item_bbs.vote.items
							, comment_writing_fg: this.item_bbs.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getCartelNoticeInfo()
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCheer: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cheer_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
						}
						, type: true
					})

					if(result.success){
						//this.$emit('to', { name: 'mafia053', params: { idx: this.cartl_number, code: this.board_type, b_id: this.board_number }})

						let item = {
							cartl_number: this.cartl_number
							, board_type: this.board_type
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
						}
						this.$emit('click', item)
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postBbs: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_bbs_modify
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.cartl_number
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							//, vote_fg: this.vote_fg ? 'Y' : 'N'
							//, vote_info: this.vote_info
							//, vote_list: this.item_bbs.vote.items
						}
						, type: true
					})

					if(result.success){
						// this.$emit('to', { name: 'mafia049', params: { idx: this.cartl_number, code: this.board_type }})

						let item = {
							cartl_number: this.cartl_number
							, board_type: this.board_type
							, board_number: this.board_number
							, bulletin_number: this.bulletin_number
						}
						this.$emit('click', item)
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_menu
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
					}
					, type: true
				})

				if (result.success) {
					this.items_board_config = result.data.board_list
					if(!this.$route.params.b_id && !this.bbs.bulletin_number){
						this.$set(this, 'item_board_config', this.items_board_config[0])
					}
				} else {
					throw result.message
				}
			} catch (e) {
				console.log(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onBoard: function(){
			this.is_select = true
		}
		, setBoard: function(item){
			this.is_select = false
			this.item_board_config = item
			this.item_bbs.code = item.code
			console.log('this.item_board_config', this.item_board_config)
			//this.$emit('to', { name: this.$route.name, params: { idx: this.$route.params.idx, code: item.code}})
		}
		, removeFile: function(index){
			this.files.splice(index, 1)
			this.upload_files.splice(index, 1)
		}
		, removeVideo: function(index){
			this.video.splice(index, 1)
		}
		, getBbs: function(){

			switch (this.board_type){
				case 'CA00700001':
					this.getCartelNoticeInfo()
					break
				case 'CA00700002':
					this.getCartelCheerInfo()
					break
				default:
					this.getCartelBbs()
					break
			}
		}
		, getCartelBbs: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false
					this.vote_fg = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					/*
					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
*/

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							console.log(key)
							this.files.push({
								name: val.post_file_name
								, src: val.post_file_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelNoticeInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_notice_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.is_vote = result.data.vote_fg == 'Y' ? true : false

					this.item_bbs.content = this.item_bbs.post_contents
					this.item_bbs.title = this.item_bbs.post_title
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'

					this.files = []
					this.upload_files = []
					if(this.item_bbs.post_file_list.length > 0){
						for(let [key, val] of this.item_bbs.post_file_list.entries()){
							console.log(key)
							this.files.push({
								name: val.post_file_name
								, src: val.post_file_url
								, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
							})
							this.upload_files.push(val)
						}
					}

				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getCartelCheerInfo: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_cheer_info
					, data: {
						member_number: this.user.member_number

						, cartl_number: this.cartl_number
						, board_number: this.board_number
						, bulletin_number: this.bulletin_number
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					this.board_type = this.item_bbs.board_type_code

					this.item_bbs.content = this.item_bbs.post_contents
					this.$set(this.item_bbs, 'is_comment',  this.item_bbs.comment_writing_fg == 'Y' ? true : false)

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPopup: function(){
			this.is_popup = true
		}
		, setFile: function(e){
			console.log('setFile')
			console.log(e[0].size / 1024)

			let file_count = this.files.length + e.length
			if(file_count > this.file_max){
				this.$bus.$emit('notify', { type: 'error', message: this.$language.common.error_file_limit})
				return false
			}

			console.log('for files')
			let self = this
			for(let file of e){
				console.log(`file` , file)
				this.upload_files.unshift(file)
				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = function(e){
					console.log('reader.onload')
					data.src = e.target.result
					self.files.unshift(data)
				}

				reader.readAsDataURL(file)
			}
		}
		, setVideo: function(e){

			for(let video of e.target.files){
				const reader = new FileReader()
				let self = this
				reader.onload = function(e){
					self.video.unshift(e.target.result)
				}
				console.log('video ', video)
				reader.readAsDataURL(video)
			}
		}
		, handleClick(event, ref) {
			this.$refs[ref][event]();
		}
		, handleSearch(e, platform) {
			if (platform === "youtube") this.youtubeLink = e.target.value;
			else this.vimeoLink = e.target.value;
		}
		, setVideoSize: function(type, index){
			if(this.video_size > 5){
				return false
			}
			if(type){
				this.video_size++
			}else{
				this.video.splice(this.video_size - index, 1)
				this.video_size--
			}
		}
		, setVote: function(e){
			this.$set(this.item_bbs, 'vote', e)
		}
		, resetVote: function(){
			this.$bus.$emit('notify', { type: 'error', message: '글수정시 투표등록, 수정 및 삭제가 불가능합니다'})
			return false
		}
		, removeVote: function(){
			this.item_bbs.vote = null
			this.is_vote = false
			this.vote_fg = false
		}

		, reSize: function(e){
			console.log('reSize', e)
			/*
			e.target.style.height = 'auto'
			e.target.style.height = e.target.scrollHeight + 'px'

			 */
		}
		, controlVideo: function(index){
			console.log('this.$refs video_' + index, this.$refs['video_' + index])
			this.$refs['video_' + index][0].play()
		}
		, doPost: function(){
			switch (this.board_type){
				case 'CA00700001':
					this.postNotice()
					break
				case 'CA00700002':
					this.postCheer()
					break
				default:
					this.postBbs()
					break
			}
		}
		, makeEnddate: function(){
			return this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
		}
		, goBack: function(){

			if(this.cartel){

				let item = {
					cartl_number: this.cartl_number
					, board_type: this.board_type
					, board_number: this.board_number
					, bulletin_number: this.bulletin_number
				}

				this.$emit('click', item)
			}else{
				this.$router.back()
			}
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		/*
		await this.getCartel()
		await this.getBoardList()
		await this.getBoardConfig()
		*/

		console.log('mafia127-2', this.bbs)

		if(this.cartel){
			this.item_cartel = this.cartel
		}else{
			await this.getCartel()
		}

		await this.getBoardList()
		await this.getBoardConfig()
		await this.getBbs()

	}


}
</script>