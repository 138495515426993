<template>
	<div
		class="box-etc position-relative"
	>
		<div
			style="position: absolute; right: -100px; top: 0px; z-index: 999"
		>
			<div
				v-if="is_join"
				class="cursor-pointer bg-primary pa-20"
				style="width:80px; height: 80px; border-radius: 100%"
				@click="toJoin"
			>
				카르텔 가입
			</div>

			<div
				v-if="is_write"
				class="cursor-pointer"
				@click="toWrite"
			>
				<img :src="require('@/assets/image/web/home_cartel/ico_write.svg')" alt="글쓰기"/>
			</div>
		</div>

		<div
			v-if="is_notice"
			class="bg-white pa-20 radius-20 mb-30"
		>
			<div class="flex-row justify-space-between">
				<h4>공지사항</h4>
			</div>
			<div class="mt-20">
				<ul
					v-if="items_notice.length > 0"
				>
					<li
						v-for="(notice, n_index) in list_notice"
						:key="'notice_' + n_index"
						class="flex-row justify-space-between under-line-not-last ptb-10 cursor-pointer"
						@click="toDetail(notice)"
					>
						<div><span class="label label-notice">공지</span> {{  notice.post_title | maxLength(20, '...') }}</div>
						<div>{{  notice.date }}</div>
					</li>
				</ul>
				<div
					v-else
					class="none"
				>등록된 공지사항이 없습니다.</div>

				<Pagination
					:options="item_search_notice"
					@click="getSearchNoticeList"
				></Pagination>
			</div>
		</div>

		<div
			class="bg-white radius-20 text-left flex-column mb-30"
			v-if="bbs_info.bulletin_number"
		>
			<div
				v-if="mode == 'comment'"
				class="pa-20"
			>
				<span class="comment_count">댓글 <em class="count">{{  bbs_info.comment_tot_count }}</em>개</span>
			</div>
			<div
				v-else-if="mode == 'reply'"
				class="pa-20 flex-row justify-space-between items-center"
			>
				<span class="comment_count">답글쓰기</span>
				<v-icon class="cursor-pointer" @click="cancelReply">mdi-close-circle-outline</v-icon>
			</div>
			<div
				v-else-if="mode == 'modify'"
				class="pa-20 flex-row justify-space-between items-center"
			>
				<span class="comment_count">댓글 수정</span>
				<v-icon class="cursor-pointer" @click="cancelReply">mdi-close-circle-outline</v-icon>
			</div>
			<div
				class="mt-10 prl-20 wrap-comment flex-1 position-relative"
				style="min-height: 120px; max-height: 480px; overflow: auto"
				ref="body_comment"
			>
				<ul
					ref="post_comment_main_view"
					style="padding-bottom: 50px"
				>
					<li
						v-for="(comment, c_index) in list_comment"
						:key="'comment_' + c_index"
						:class="{ hide: mode == 'reply' && comment.comment_number != item_comment.upper_comment_number && comment.upper_comment_number != item_comment.upper_comment_number, on: mode == 'reply' && comment.comment_number == item_comment.comment_number}"
					>
						<div
							class="post_comment_main_view"
						>
							<div
								class="comment_layout"
							>
								<template
									v-if="comment.comment_level == 0"
								>
									<div
										v-if="comment.comment_delete_fg == 'Y'"
										class="comment_view"
									>
										<div class="item_wrap">
											<div class="write_user">
												<a class="u_profile">
													<img
														:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
													>
												</a>
												<div class="write_desc">
													<strong class="name">{{  $language.common.text_delete_comment }}</strong>
												</div>
											</div>
											<div class="comment_body">
												<p style="word-break:break-all;"></p>

											</div>
										</div>
									</div>
									<div
										v-else
										class="comment_view"
									>
										<div class="item_wrap">
											<div class="write_user flex-row justify-space-between items-center">
												<div>
													<a class="u_profile radius-100">
														<img
															:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="사진없음"
															@error="$bus.$emit('onErrorProfileImage', $event)"
															class="width-100"
														>
													</a>
													<div class="write_desc">
														<strong class="name"><em v-if="comment.is_block" class="color-red">차단</em> {{  comment.comment_member_nickname }}</strong>
													</div>
												</div>

												<button
													class="post_set"
													@click="onMore(comment, c_index)"
												><span class="hide">설정</span></button>
											</div>
											<div class="comment_body">
												<p :inner-html.prop="comment.comment | nl2br" style="word-break:break-all;"></p>
												<div class="position-relative mt-10">
													<img
														:src="$request.upload_url(comment.comment_img_url)"
														@error="$bus.$emit('onErrorImage', $event)"
														class="width-100"
													/>
													<v-icon
														v-if="comment.comment_img_url"
														@click="toViewer(comment)"
														class="ml-10 mt-5 position-absolute color-white bg-black pa-3 box"
														style="right: 10px; top: 10px"
													>mdi-arrow-expand-all</v-icon>
												</div>
											</div>
											<div class="write_info">
												<span class="time">{{  getTimeStory('comment', comment.registdate) }}</span>
												<button
													class="reply"
													@click="setReply(c_index, comment)"
												>답글쓰기</button>
											</div>
										</div>
									</div>
								</template>
								<!-- 재댓글 -->
								<div
									v-else
									class="replylist_view"
								>
									<div
										class="item_wrap"
									>
										<template
											v-if="comment.comment_delete_fg == 'Y'"
										>
											<div class="write_user">
												<a class="u_profile">
													<img
														:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
													>
												</a>
												<div class="write_desc">
													<strong class="name" >{{  $language.common.text_delete_comment }}</strong>
												</div>
											</div>
										</template>
										<template
											v-else
										>
											<div class="write_user flex-row justify-space-between items-center">
												<div>
													<a class="u_profile">
														<img
															v-if="comment.comment_member_profle_nft_card_img_url"
															:src="$request.upload_url(comment.comment_member_profle_nft_card_img_url)" alt="사진없음"
														>
														<img
															v-else
															:src="require('@/assets/image/@profile_inner.png')" alt="사진없음"
														>
													</a>
													<div class="write_desc">
														<strong class="name" ><em v-if="comment.is_block" class="state-block">차단</em>  {{  comment.comment_member_nickname }}</strong>
													</div>
												</div>

												<button
													class="post_set"
													@click="onMore(comment, c_index)"
												><span class="hide">설정</span></button>
											</div>
											<div class="comment_body position-relative">
												<button class="hashtag" style="position: absolute; top: 0px">&#64;{{ comment.comment_member_nickname }}</button>
												<p style="">&#64;{{ comment.comment_member_nickname}} {{ comment.comment }}</p>
												<div class="position-relative">
													<img
														v-if="comment.comment_img_url"
														:src="$request.upload_url(comment.comment_img_url)"

														style="width: 100%; margin-top: 10px"
													/>
													<v-icon
														v-if="comment.comment_img_url"
														@click="toViewer(comment)"
														class="ml-10 mt-5 position-absolute color-white bg-black pa-3 box"
														style="right: 10px; top: 10px"
													>mdi-arrow-expand-all</v-icon>
												</div>
											</div>
											<div class="write_info">
												<span class="time">{{  getTimeStory('reply', comment.registdate) }}</span>
												<button
													class="reply"
													@click="setReply(c_index, comment)"
												>답글쓰기</button>
											</div>
										</template>
									</div>
								</div>
								<!-- //재댓글 -->
							</div>
							<!-- 댓글 -->
						</div>
					</li>
				</ul>
				<div
					v-if="items_comment.length < 1"
					class="none"
				>등록된 댓글이 없습니다</div>
			</div>
			<div
				class=""
				style="position: relative; height: 65px"
			>
				<div
					class="top-line"
					style="position: absolute; left: 0; bottom: 0; width: 100%; background-color: white; border-radius: 0px 0px 20px 20px"
				>
					<div
						v-if="bbs_info.comment_writing_fg != 'N'"
						class=""
					>
						<div
							v-if="item_comment.img_src"
							class="photo_area bg-eee"
						>
							<div class="photo_area_view">
								<img
									v-if="item_comment.img_src"
									:src="$request.upload_url(item_comment.img_src)"
								/>
							</div>
							<button
								@click="item_comment.img_src = ''"
								class="view_close"
							><i class="icon-close"></i></button>
						</div>
					</div>
					<div class="flex-row pa-20">
						<div class="flex-column justify-end">
							<label
								class="cursor-pointer"
							><input_file type="file" @change="setFile" accept="image/*" class="hide"/><img :src="require('@/assets/image/web/notify_cartel/ico_noti_photo.svg')"/></label>
						</div>
						<div class="flex-1 prl-10">
							<textarea
								v-model="item_comment.content"
								placeholder="댓글을 남겨주세요."

								class="scroll-black comment-box"

								@input="reSize"
								style="width: 100%;"
								:style="item_comment.content == '' ? 'height: 20px': 'height: auto'"
								ref="comment_content"
							></textarea>
						</div>
						<div class="flex-column justify-end">
							<button
								@click="doPost"
							><img :src="require('@/assets/image/web/notify_cartel/ico_comment_add.svg')"/></button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<template
			v-else
		>
		<div
			class="bg-white pa-20 radius-20 "
		>
			<div class="flex-row justify-space-between">
				<h4>NFT DROPS</h4>
				<div><img :src="require('@/assets/image/web/index_cartel/ico_arrow_right_lg.svg')" style="width: 10px"></div>
			</div>
			<div class="mt-20">
				<div class="prl-50">
					<div class="position-relative">
						<img :src="require('@/assets/image/web/nft_collection/bg_dropbox_sm.jpg')" class="radius-20" />
						<div
							class="position-absolute flex-column justify-center items-center"
							style="left: 0; top: 0; width: 100%; height: 100%; background: linear-gradient(180deg, rgba(255, 255, 255, 0) 46.35%, rgba(255, 255, 255, 0.7) 100%);"
						>
							<img :src="require('@/assets/image/web/nft_collection/ico_dropbox_lg.svg')" />
							<h4 class="mt-10 color-primary">NFT CARD</h4>
						</div>
					</div>
					<div class="mt-20 color-primary size-px-16 font-weight-bold">price</div>
					<div class="mt-10 color-gray">랜덤 NFT카드를 구입하세요.</div>
					<div class="mt-20">
						<button
							class="btn btn-primary radius-20"
							@click="toDrops"
						>BUY</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="bg-white pa-20 radius-20 mt-30"
		>
			<div class="flex-row justify-space-between">
				<div>NFT DROPS</div>
				<div><img :src="require('@/assets/image/web/index_cartel/ico_arrow_right_lg.svg')" style="width: 10px"></div>
			</div>
			<div
				class="mt-20 "
				style="overflow: hidden;white-space: nowrap"
			>
				<ul>
					<li class="inline-block mr-10">
						<div>
							<img :src="require('@/assets/image/web/nft_collection/bg_dropbox_sm.jpg')" />
							<div>
								<div class="mt-10">랜덤 NFT카드를 구입하세요.</div>
								<div class="mt-20">price</div>
							</div>
						</div>
						<div class="mt-20">
							<button
								class="btn btn-blue radius-20"
							>BUY</button>
						</div>
					</li>
					<li class="inline-block mr-10">
						<div>
							<img :src="require('@/assets/image/web/nft_collection/bg_dropbox_sm.jpg')" />
							<div>
								<div class="mt-10">랜덤 NFT카드를 구입하세요.</div>
								<div class="mt-20">price</div>
							</div>
						</div>
						<div class="mt-20">
							<button
								class="btn btn-blue radius-20"
							>BUY</button>
						</div>
					</li>
					<li class="inline-block mr-10">
						<div>
							<img :src="require('@/assets/image/web/nft_collection/bg_dropbox_sm.jpg')" />
							<div>
								<div class="mt-10">랜덤 NFT카드를 구입하세요.</div>
								<div class="mt-20">price</div>
							</div>
						</div>
						<div class="mt-20">
							<button
								class="btn btn-blue radius-20"
							>BUY</button>
						</div>
					</li>
				</ul>
			</div>
		</div>
		</template>

		<PopupConfirm
			v-if="is_to_join"

			@click="postCartelJoin"
			@cancel="is_to_join = false"
		>
			<template
				v-slot:title
			>카르텔 가입</template>
			<template
				v-slot:main-txt
			>
				<div class="mt-20">{{ cartel_info.cartl_name }}</div>
			</template>
			<template
				v-slot:sub-txt
			>
				<div cass="mt-20">{{ cartel_info.cartl_desctn }}</div>
			</template>
			<template
				v-slot:content
			>
				<div class="color-gray mt-20">이 카르텔에서 활동하는 동안 원활한 카르텔 운영을 위하여 아이디, 별명, 활동내역이 카르텔의 운영진에게 공개되며 카르텔 정책에 위반된 활동을 할 경우 제재를 받을 수 있습니다. 본 동의를 거부하실 수 있으나, 카르텔 가입이 불가능합니다.</div></template>
		</PopupConfirm>

		<PopupConfirm
			v-if="is_confirm"
			:not_cancel="true"
			@click="toHome"
			@cancel="toHome"
		>
			<template v-slot:title>카르텔 가입</template>
			<template
				v-if="cartel_info.entry_approval_fg == 'Y'"
				v-slot:main-txt
			>해당 카르텔은 관리자 승인이 필요합니다</template>
			<template
				v-if="cartel_info.entry_approval_fg == 'Y'"
				v-slot:sub-txt
			>관리자 승인 후 카르텔 활동이 가능합니다</template>
			<template
				v-else
				v-slot:sub-txt
			>카르텔 가입이 완료되었습니다</template>
		</PopupConfirm>

		<mafia062
			v-if="is_062"

			:user="user"
			:cartel="cartel_info"
			:bbs="bbs_info"
			:item_comment="item_target"

			@cancel="is_062 = false"
			@click="getBbsComment"
			@modify="onModify"
			@delete="deleteComment"
			style="z-index: 1003"
		>
		</mafia062>

		<mafia0632
			v-if="is_0632"

			:user="user"
			:cartel="cartel_info"
			:bbs="bbs_info"
			:comment="item_target"

			@cancel="is_0632 = false"
			@click="update"
			class="layer-popup"
		></mafia0632>
	</div>
</template>

<script>
	import input_file from '@/components/InputFile'
	import PopupConfirm from "@/view/Layout/PopupConfirm";
	import Mafia062 from "@/view/Cartel/mafia062";
	import Mafia0632 from "@/view/Cartel/mafia063-2";
	import Pagination from "@/components/Pagination";

	export default {
		name: 'CartelEtc'
		, props: ['user', 'cartel_info', 'bbs_info']
		, components: {Mafia0632, Mafia062, PopupConfirm, input_file, Pagination}
		, data: function() {
			return {
				items_comment: []
				, item_search: {
					page_number: 1
					, pagerecnum: 10
				}
				, item_comment: {
					cartel_id: this.$route.params.idx
					, bbs_code: this.$route.params.code
					, bbs_id: this.$route.params.bbs_id
					, user_id: this.user.id
					, user_nick: this.user.nick
					, user_img_src: this.user.img_src
					, upper_comment_number: ''
					, upper_comment_member_nickname: ''
					, content: ''
					, comment_img_url: ''
					, comment_level: 0
					, comment_member_profle_nft_card_img_url: ''
					, comment_number: ''
					, img_src: ''
				}
				, rows: 1
				, upload_img: ''
				, scrollTop: 0
				, is_scroll: false
				, h: 0
				, is_to_join: false
				, is_to_write: false
				, is_confirm: false
				, is_062: false
				, item_target: {}
				, is_0632: false
				, mode: 'comment'
				, upper_index: 0
				, items_notice: []
				, item_search_notice: {
					page_number: 1
					, pagerecnum: 5
					, page_cnt: 5
				}
			}
		}
		, computed: {
			list_comment: function(){
				return this.items_comment.filter(function(item){
					if(item.blok_member_fg == 'Y'){
						item.is_block = true
					}
					return item
				})
			}
			, list_notice: function(){
				return this.items_notice.filter((item) => {
					item.date = (item.registdate).substring(0, 10)
					return item
				})
			}
			, is_notice: function(){
				let t = false
				if(this.$route.params.code == 'CA00700001' || (this.$route.params.code != 'CA00700001' && !this.$route.params.bbs_id)){
					t = true
				}
				return t
			}
			, is_write: function(){
				let t = false
				if(this.cartel_info.cartl_entry_state_code == 'CA00300004'){
					t = true
				}
				return t
			}
			, is_join: function(){
				let t = false
				if(this.cartel_info.cartl_entry_state_code == ''){
					t = true
				}
				return t
			}
			, is_join_confirm: function(){
				let t = false
				switch(this.cartel_info.cartl_entry_state_code) {
					case 'CA00300004':
						t = true
						break
				}
				return t
			}
			, is_join_cancel: function (){
				let t = false
				switch(this.cartel_info.cartl_entry_state_code){
					case 'CA00300003':
					case 'CA00300005':
					case 'CA00300006':
					case 'CA00300007':
						t = true
						break;
				}
				return t
			}
			, is_join_wait: function(){
				let t = false
				switch(this.cartel_info.cartl_entry_state_code){
					case 'CA00300001':
					case 'CA00300002':
						t = true
						break;
				}
				return t
			}
		}
		, methods: {
			getBbsComment: async function(){
				if(!this.is_scroll) {
					this.is_scroll = true
					try {
						// this.$bus.$emit('on', true)
						const result = await this.$request.init({
							method: 'post'
							, url: this.$api_url.api_path.get_bbs_comment_list
							, data: {
								member_number: this.user.member_number
								, cartl_number: this.$route.params.idx
								, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
								, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
								, page_number: this.item_search.page_number
								, pagerecnum: this.item_search.pagerecnum
							}
							, type: true
						})

						if (result.success) {
							if (this.items_comment.length > 0) {
								console.log('concat')
								this.items_comment = this.items_comment.concat(result.data.comment_list)
							} else {
								console.log('!!concat')
								this.items_comment = result.data.comment_list
							}

							this.item_search.page_number++

							if (result.data.comment_list.length > 0) {
								this.getScroll()
							} else {
								this.removeScroll()
							}
						} else {
							throw result.message
						}
					} catch (e) {
						console.log(e)
						this.$bus.$emit('notify', {type: 'error', message: e})
					} finally {
						this.$bus.$emit('on', false)
						this.is_scroll = false
					}
				}
			}

			, doPost: function(){
				if(this.mode == 'modify'){
					this.postCommentModify()
				}else{
					this.postComment()
				}
			}

			, postComment: async function(){
				try{
					if(!this.item_comment.content || this.item_comment.content == ''){
						throw '댓글을 입력하세요'
					}
					// this.$bus.$emit('on', true)

					if(await this.postFile()){
						const result = await this.$request.init({
							method: 'post'
							, url: this.$api_url.api_path.post_comment
							, data: {
								member_number: this.user.member_number
								, cartl_number: this.$route.params.idx
								, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
								, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
								, upper_comment_number: this.item_comment.upper_comment_number
								, upper_comment_member_nickname: this.item_comment.upper_comment_member_nickname
								, comment: this.item_comment.content
								, comment_img_url: this.item_comment.comment_img_url
							}
							, type: true
						})

						if(result.success){
							this.item_comment.comment_number = result.data.comment_number
							this.item_comment.registdate = this.$date.getNow('-')
							this.item_comment.comment = this.item_comment.content
							this.item_comment.comment_member_profle_nft_card_img_url = this.user.profle_nft_card_img_url
							this.item_comment.comment_level = this.item_comment.upper_comment_number ? 1 : 0
							this.item_comment.comment_member_nickname = this.user.nickname
							this.item_comment.comment_member_number = this.user.member_number

							await this.appendComment(this.item_comment)
							this.item_comment.content = ''
							this.item_comment = {
								cartel_id: this.$route.params.idx
								, bbs_code: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
								, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.board_number
								, user_id: this.user.id
								, user_nick: this.user.nick
								, user_img_src: this.user.img_src
								, upper_comment_number: ''
								, upper_comment_member_nickname: ''
								, content: ''
								, comment_img_url: ''
							}

							this.mode = 'comment'
							setTimeout(() => {
								this.$refs.body_comment.scrollTop = this.$refs.body_comment.scrollHeight
							}, 100)

							this.$emit('click')
						}else{
							throw result.message
						}
					}

				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, postCommentModify: async function(){
				try{
					if(!this.item_comment.content || this.item_comment.content == ''){
						throw '댓글을 입력하세요'
					}
					this.$bus.$emit('on', true)
					if(await this.postFile()) {
						const result = await this.$request.init({
							method: 'post'
							, url: this.$api_url.api_path.post_comment_modify
							, data: {
								member_number: this.user.member_number
								, cartl_number: this.$route.params.idx ? this.$route.params.idx : this.cartel.cartl_number
								, board_number: this.$route.params.b_id ? this.$route.params.b_id : this.bbs.board_number
								, bulletin_number: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.bulletin_number
								, comment_number: this.item_comment.comment_number
								, comment: this.item_comment.content
								, comment_img_url: this.item_comment.comment_img_url
							}
							, type: true
						})

						if (result.success) {
							this.mode = 'comment'
							this.$set(this.items_comment, this.item_target.index, {
								comment_number: this.item_comment.comment_number
								, registdate: this.item_target.registdate
								, comment: this.item_comment.content
								, comment_member_profle_nft_card_img_url: this.user.profle_nft_card_img_url
								, comment_level: this.item_comment.upper_comment_number ? 1 : 0
								, comment_member_nickname: this.user.nickname
								, comment_member_number: this.user.member_number
								, comment_img_url: this.item_comment.img_src
							})

							this.item_comment = {
								cartel_id: this.$route.params.idx
								, bbs_code: this.$route.params.code ? this.$route.params.code : this.bbs.board_type_code
								, bbs_id: this.$route.params.bbs_id ? this.$route.params.bbs_id : this.bbs.board_number
								, user_id: this.user.id
								, user_nick: this.user.nick
								, user_img_src: this.user.img_src
								, upper_comment_number: ''
								, upper_comment_member_nickname: ''
								, content: ''
								, comment_img_url: ''
							}
						}
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}

			, postFile: async function(){
				try{
					// this.$bus.$emit('on', true)
					if(!this.upload_img){
						return true
					}
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_file
						, data: {
							member_number: this.user.member_number
							, file_upload_code: 'CM00700003'
							, appendix_file_div_code: 'CA01000001'
							, appendix_file_name: this.upload_img
						}
						, multipart: true
						, type: true
					})

					if(result.success){
						this.item_comment.comment_img_url = result.data.file_url
						return true
					}else{
						throw result.message
					}

				}catch (e) {
					console.log('postFile error', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
					this.upload_img = ''
				}
			}
			, getTimeStory: function(type, created_at){
				// console.log(type, created_at)
				return this.$date.getTimeStory(created_at)
			}
			, setFile: function(e){

				this.upload_img = e[0]
				console.log('this.upload_img', this.upload_img)

				const reader = new FileReader()

				reader.onload = (e) => {
					// console.log('onload', e)
					this.$set(this.item_comment, 'img_src', e.target.result)
				}
				reader.readAsDataURL(e[0])

			}
			, scrollListen: async function(){
				if(this.mode == 'reply'){
					return false
				}else{

					let w = this.$refs.body_comment.clientHeight
					let b = this.$refs.post_comment_main_view.scrollHeight
					let t = this.$refs.body_comment.scrollTop

					let max = b - w
					// console.log(w, b, t, max)
					if(max <= 0){
						max = 0
					}

					if(t > (max - 20)){
						await this.getBbsComment()
					}
				}
			}
			, removeScroll: function(){
				console.log('removeScroll')
				if(this.bbs_info.bulletin_number){
					this.$refs.body_comment.removeEventListener('scroll', this.scrollListen)
				}
			}
			, getScroll: function(){
				this.h = this.$refs.post_comment_main_view.scrollHeight
				console.log('getScroll', this.h)
				this.$refs.body_comment.addEventListener('scroll', this.scrollListen);
			}

			,appendComment: function(obj){

				if(obj.upper_comment_number){

					this.items_comment.splice(this.upper_index + 1, 0, obj)

					setTimeout(() => {
						this.$refs.body_comment.scrollTop = this.s_top
					}, 100)
				}else{
					this.items_comment.push(obj)
				}
			}

			, reSize: function(e){
				e.target.style.height = '20px'

				e.target.style.height = (e.target.scrollHeight) + 'px'

				console.log('e.target.innerHeight', e.target.innerHeight)
				if(Number(e.target.style.height.replace('px', '')) >= 120) {
					e.target.style.height = 120 + 'px'
				}else{
					if(e.target.style.height == '40px'){
						if(e.target.value.search(/\n/g) == -1){
							e.target.style.height = '20px'
						}
					}
				}
			}
			, cancelReply: function(){
				this.upper_index = 0
				this.item_comment.comment_number = ''
				this.item_comment.upper_comment_number = ''
				this.item_comment.upper_comment_member_nickname = ''
				this.$set(this.item_comment, 'reply_id', '')
				this.mode = 'comment'

				setTimeout( () => {
					this.$refs.body_comment.scrollTop = this.s_top
				}, 100)

			}
			, setReply: function(index, comment){

				this.s_top = this.$refs.body_comment.scrollTop
				this.upper_index = index
				this.item_comment.comment_number = comment.comment_number
				if(comment.comment_level == 0){
					this.item_comment.upper_comment_number = comment.comment_number
				}else{
					this.item_comment.upper_comment_number = comment.upper_comment_number
				}

				this.item_comment.upper_comment_member_nickname = comment.comment_member_nickname
				this.$set(this.item_comment, 'reply_id', comment.comment_level == 0 ? '' : comment.comment_number)
				this.mode = 'reply'

				console.log(`comment.upper_comment_number :${comment.upper_comment_number}, comment.comment_number: ${comment.comment_number}, this.item_comment.reply_id: ${this.item_comment.reply_id}`)

			}
			, toJoin: function(){
				this.is_to_join = true
			}
			, toWrite: function(){
				this.$bus.$emit('to', {name: 'mafia1271', params: {idx: this.$route.params.idx, code: this.$route.params.bulletin_number}})
			}

			,postCartelJoin: async function(){
				try{
					this.$bus.$emit('on', true)

					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_join
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})
					if(result.success){
						this.is_confirm = true
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
					this.is_to_join = false
				}
			}
			, toHome: function(){
				this.$bus.$emit('to', { name: 'mafia049', params: {idx: this.$route.params.idx }})
			}
			, toViewer: function(comment){
				this.$bus.$emit('viewer', {
					post_file_url: comment.comment_img_url
				})
			}

			, onMore: function(item, index){
				console.log('longPress', item)
				this.is_062 = true
				this.item_target = item
				this.item_target.index = index
			}
			, onModify: function(){
				this.is_062 = false
				this.item_comment.content = this.item_target.comment
				this.item_comment.comment_number = this.item_target.comment_number
				this.$set(this.item_comment, 'img_src', this.item_target.comment_img_url)
				this.mode = 'modify'
				this.reSize({ target: this.$refs.comment_content})
			}
			, update: function(item){
				console.log('update', this.item_target, item)

				item.comment = item.content
				this.$set(this.items_comment, this.item_target.index, item)

				this.is_0632 = false
			}
			, deleteComment: function(){
				this.items_comment.splice(this.item_target.index, 1)
				this.is_062 = false
			}
			, getSearchNoticeList: function(page = 1){
				this.item_search_notice.page_number = page
				this.getNoticeList()
			}
			, getNoticeList: async function(){
				try {
					// this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_notice_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							//, board_number: this.$route.params.b_id
							, page_number: this.item_search_notice.page_number
							, pagerecnum: this.item_search_notice.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_notice = result.data.post_list
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toDetail: function(item) {
				console.log('item', item)
				this.$bus.$emit('to', { name: 'mafia058', params: { idx: this.$route.params.idx, code: item.board_type_code, b_id: item.board_number, bbs_id: item.bulletin_number}})
			}
			, toDrops: function(){
				this.$bus.$emit('to', { name: 'drops'})
			}
			, mount: function(){

				this.item_search.page_number = 1
				this.items_comment = []
				if (this.$route.params.bbs_id) {
					setTimeout(() => {
						this.getBbsComment()
						this.getNoticeList()
					}, 500)
				} else {
					this.getNoticeList()
				}
			}
		}
		, created() {
			console.log('this.bbs_info', this.bbs_info)

			console.log(this.$route.params)
			this.mount()
		}
		, watch: {

			$route(to, from){
				this.mount()
				console.log(to, from)
			}
		}
	}
</script>

<style>

	.wrap-comment::-webkit-scrollbar{
		width: 2px;
		background-color: #333;
	}
	.wrap-comment::-webkit-scrollbar-thumb {
		background-color: #2f3542;
	}
	.wrap-comment::-webkit-scrollbar-track {
		background-color: #bbb;
	}

	.comment-box::-webkit-scrollbar{
		width: 0px;
	}

	.post_comment_main_view { padding: 10px 0; border-bottom: 1px solid var(--light-Gray01); }
	.wrap-comment li:last-child .post_comment_main_view { border-bottom: none}
	.post_comment_main_view .comment_layout + .comment_layout { border-bottom: 1px solid var(--light-Gray01); }
	.comment_count { display: block; font-size: 16px; color: var(--dark-Gray02); font-weight: 500; }
	.comment_count .count { font-size: inherit; color: inherit; font-weight: 600; }


	.comment_layout { position: relative; padding: 0; }
	.item_wrap { position: relative; padding: 0 0 0 4.2rem; }
	.item_wrap .write_user .u_profile { position: absolute; left: 0; top: 0; }
	.item_wrap .write_user .write_desc { display: flex; justify-content: space-between; }
	.item_wrap .write_user .write_desc .name { display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 16px; line-height: 3.2rem; font-weight: 500; color: var(--dark-Gray02); }
	/* .item_wrap .write_info .write_desc .time { display: block; margin-left: 1rem; flex: 1; min-width: 5rem; line-height: 3.2rem; font-size: 1.4rem; color: var(--gray02); } */
	.item_wrap .comment_body { font-size: 0;}
	.item_wrap .comment_body p { display: inline-block; font-size: 14px; line-height: 14px; color: var(--dark-Gray02); }
	.item_wrap .write_info { margin-top: 0.8rem; color: var(--gray02); font-size: 0; }
	.item_wrap .write_info .time { display: inline-block; margin-right: 1rem; font-size: 14px; line-height: 1; vertical-align: top; }
	.item_wrap .write_info .reply { display: inline-block; font-size: 14px; line-height: 1; color: var(--gray02);  vertical-align: top; }
	.hashtag { display: inline-block; margin-right: 1rem; font-size: 14px; line-height: 14px; color: var(--blue01); font-weight: 500; vertical-align : top;}

	.replylist_view { padding: 0 0 0 4.2rem; }
	.replylist_view .item_wrap {  }

	/* min-height: 5rem; */
	.comment_write { filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.05))}

	.photo_area { position: relative; }
	.photo_area::before { content: ''; position: absolute; top:0; left: 0; background: black; opacity: 0.3; }
	.photo_area .photo_area_view { position: relative; padding: 0; height: 200px; max-width: 100%; margin: 0 auto; }
	.photo_area .photo_area_view img { height: 100%; width: 100%; object-fit: contain; }

	.view_close { position: absolute; right: 10px; top: 10px; background-color: #bbb; padding: 0px 5px 3px}
	.view_close i { font-size: 1.2px; color: #fff; }

	.write_area { background-color: #fff; padding: 10px}

	.write_main { display: flex; align-items: flex-end; }

	.btn_upload { flex-shrink: 1; position: relative; display: block; width: 5rem; min-height: 5rem; }
	.comment_albumfile:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 24px; height: 24px; background: url(../../assets/image/ico_camera.png) no-repeat center center / 24px auto;}

	.mentions { flex: 1; margin: 1.5rem 0; height: auto; }
	.mentions .hashtag { line-height: 2.4rem;}
	.mentions textarea { line-height: 20px; width: 100%; font-size: 16px }
	.mentions textarea::placeholder { color: var(--gray01); }

	.submit_wrap .write_submit { flex-shrink: 1; position: relative; display: block;  width: 5rem; min-height: 5rem;}
	.write_submit:after { content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 24px; height: 24px; background: url(../../assets/image/ico_submit.png) no-repeat center center / 24px auto; }

	.hide{ display: none}

	.wrap-comment .on { background-color: #eee}
</style>